import React, { useEffect, useRef, useState, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./Jobs.css";
// import http from "./http-common";
import axios from "axios";

import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import GetAppIcon from "@mui/icons-material/GetApp";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import MenuIcon from "@mui/icons-material/Menu";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListIcon from "@mui/icons-material/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForever from "@mui/icons-material/DeleteForever";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ProfileIcon from "@mui/icons-material/AccountCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import { inferURLs, apiURLs, ohifURLs } from "./config";
import { CircularProgress, IconButton } from "@mui/material";

import { onLogout } from "./Login";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommentIcon from "@mui/icons-material/Comment";

import { Select, MenuItem, ListSubheader, InputAdornment } from "@mui/material";

import logo from "./urologiq.png";
import { getRandomColor, createImageFromInitials } from "./utils/Utils";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockResetIcon from "@mui/icons-material/LockReset";
import Notifications from "./utils/notifications";

import { DropzoneDialogBase } from "material-ui-dropzone";

import io from "socket.io-client";

import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Close from "@mui/icons-material/Close";

import NoteAdd from "@mui/icons-material/NoteAdd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  pt: 2,
  px: 4,
  pb: 3,
};

const styles = {
  wrapper: {
    width: "100%",
    height: "100%",
    // margin: '10px',
    // padding: '20px',
    background: "#fff",
    color: "black",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#1b4a92",
    height: "40px",
    alignItems: "center",
    padding: "5px",
    color: "#fff",
  },
  dashboardWrapper: {
    // height: '100%',
    display: "flex",
    flexDirection: "row",
  },
  dashboardSelect: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#fff",
    fontFamily: "monospace",
    height: "40px",
    padding: "0px",
    margin: "0px",
  },
  dashboardHeaderText: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#fff",
    fontFamily: "Times New Roman",
  },
  menuButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "20px",
  },
  menuButton: {
    cursor: "pointer",
  },
  logoutButton: {
    color: "#fff",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  drawer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 20px",
    width: "220px",
    height: "calc(100vh - 50px)",
    top: "50px",
    justifyContent: "flex-start",
    // background: 'red',
    background: "#fffeff",
    position: "absolute",
    zIndex: "1",
    boxShadow: "0px 3px 10px 1px rgba(0,0,0,0.75)",
    transition: "width 0.25s ease",
  },
  closedDrawer: {
    position: "absolute",
    width: "0px",
    height: "calc(100vh - 50px)",
    top: "50px",
    // background: 'red',
    zIndex: "-1",
    transition: "all 0.25s",
  },
  drawerContent: {
    marginTop: "20px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "#fff",
    color: "#111",
    height: "100vh",
    width: "100%",
    // padding: '20px',
  },
  logoutWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    // minWidth: '100px',
    marginRight: "20px",
  },
  statusWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: 'space-between',
  },
  statusObjAdmin: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Failed" ? "red" : status === "Finished" ? "green" : "#528398",
    marginRight: "4px",
  }),
  statusObj: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Failed"
        ? "red"
        : status === "completed"
        ? "green"
        : "#528398",
    marginRight: "4px",
  }),
  smallWidth: {
    maxWidth: "60px",
  },
  tableHeaderItem: {
    // color: '#049c77',
    textAlign: "center",
    verticalAlign: "middle",
    color: "#fff",
    border: "none",
    padding: "5px",
  },
  table: (pageHeight) => ({
    // borderRadius: '20px important!',
    border: "1px solid #111",
    // color: 'black',
    background: "#fff",
    color: "#111",
    // margin: '10px',
    marginBottom: pageHeight > 800 ? "10px" : "70px",
    fontWeight: "600",
    // width: '95%',
    // overflow: 'scroll',
    boxShadow: "0px 0px 10px 0px #000000",
  }),
  tableRow: (status, findings) => {
    return {
      background:
        status === "Finished"
          ? findings === true
            ? "#ffcccb"
            : "#aaeecc"
          : "#E0E0E0",
      color: "#111",
      fontWeight: "600",
      height: "30px",
      borderTop: "1px solid #0a0a12",
    };
  },
  tableBodyItem: {
    textAlign: "center",
    verticalAlign: "middle",
    maxHeight: "30px",
    borderWidth: "0px",
    borderStyle: "none",
    padding: "3px",
  },
  modal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sequencesModal: {
    background: "white",
    borderRadius: "5px",
    width: "60vw",
    height: "90vh",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-around",
    alignItems: "center",
  },
  sequencesModalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
  },
  select: {
    width: "400px",
    height: "40px",
    fontSize: "20px",
    fontWeight: "700",
    background: "#fff",
    color: "#000",
  },
  deleteModal: {
    background: "white",
    borderRadius: "5px",
    width: "450px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalBodyItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  modalButton: {
    background: "#049c77",
    color: "white",
    width: "200px",
    height: "40px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deleteJobIcon: {
    color: "red",
    cursor: "pointer",
  },
  feedbackModal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "450px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY:"scroll"
  },
  feedback: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
    padding: "10px",
    background: "#eeffee",
    marginBottom: "2px",
    marginTop: "2px",
  },
  feedbackFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  popper: {
    background: "white",
    borderRadius: "5px",
    padding: "10px",
  },
  reportModal: {
    background: "white",
    borderRadius: "5px",
    width: "500px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  reportTableHeaderItem: {
    textAlign: "center",
    verticalAlign: "middle",
    color: "#000",
  },
  reportTableBodyItem: {
    textAlign: "center",
    verticalAlign: "middle",
    maxHeight: "30px",
  },
  filterDate: {
    marginRight: "10px",
    marginLeft: "10px",
    border: "1px solid #000",
    borderRadius: "5px",
    padding: "5px",
  },

  // entries...
  entries: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 10px 25px 10px",
    // background: 'green',
  },
  entry: (status, findings) => ({
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100%",
    position: "relative",
    background:
      status === "Finished"
        ? findings === true
          ? "#ffcccb"
          : "#aaeecc"
        : "#E0E0E0",
    borderTop: "1px solid #888",
  }),
  entryopen: (status, findings) => ({
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    padding: "10px 10px 35px 10px",
    width: "100%",
    position: "relative",
    background:
      status === "Finished"
        ? findings === true
          ? "#ffcccb"
          : "#aaeecc"
        : "#E0E0E0",
    borderTop: "1px solid #888",
  }),
  entryRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "5px",
  },
  entryRowKey: {
    fontSize: "12pt",
    fontWeight: "700",
    color: "#000",
  },
  entryRowValue: {
    fontSize: "12pt",
    fontWeight: "400",
    color: "#000",
  },
  expandButtonWrapper: {
    position: "absolute",
    left: "10px",
    bottom: "2px",
    padding: "10px",
    backgroundColor: "#1b4b84",
    color: "white",
    fontSize: "12px",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  dropdownContent: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "white",
    minWidth: "220px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",
    borderRadius: "5px",
    border: "1px solid #1b4b84",
    padding: "10px",
    // transform: 'translateX(-50%)',
    right: "5px",
    height: "fit-content",
  },

  dropdownContentItem: {
    padding: "5px",
    cursor: "pointer",
    color: "#000",
    "&:hover": {
      background: "#1b4b84",
      color: "white",
    },
    display: "flex",
    alignItems: "center",
    margin: "5px",
    fontWeight: "100",
    fontSize: "1rem",
  },
  badge: {
    display: "inline-block",
    backgroundColor: "#1b4b84",
    color: "#fff",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    lineHeight: "16px",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: "9px",
    position: "relative",
    top: "-10px",
    left: "-2px",
  },
};

const Feedback = (props) => {
  return (
    <div style={styles.feedback}>
      <p style={{ fontSize: "12pt", fontWeight: "600" }}>
        {props.index}. {props.feedback}
      </p>
      <div style={styles.feedbackFooter}>
        <p style={{ fontSize: "10pt" }}>{props.feedbackBy}</p>
        <p style={{ fontSize: "10pt" }}>
          {new Date(props.feedbackDate).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
            hour12: false,
          })}
        </p>
      </div>
    </div>
  );
};

const Status = (props) => {
  return (
    <div style={styles.statusWrapper}>
      <div style={props.statusObj(props.status)}></div>
      {props.status}
    </div>
  );
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const Header = (props) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const centers = Object.keys(apiURLs).sort();

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => centers.filter((option) => containsText(option, searchText)),
    [searchText]
  );
  const [LogoimgSrc, setLogoimgSrc] = useState("");
  const [notificationOpen, setnotificationOpen] = useState(false);
  function handleAddUser() {
    navigate("/admin/add-user");
  }

  function handleDropdown(dropdown) {
    setDropdownOpen(dropdown);
    if (dropdownOpen) {
      props.toggleDropdown();
    }
  }

  React.useEffect(() => {
    setDropdownOpen(props.dropdownOpen);
  }, [props.dropdownOpen]);

  React.useEffect(() => {
    let srcX = createImageFromInitials(500, props.user, getRandomColor());
    setLogoimgSrc(srcX);
  }, []);

  return (
    <div style={styles.header}>
      <div style={styles.dashboardWrapper}>
        <div
          style={styles.menuButtonWrapper}
          className="menuButtonWrapper"
          onClick={props.toggleDrawer}
        >
          {props.admin ? (
            <MenuIcon
              style={styles.menuButton}
              onClick={(e) => {
                e.stopPropagation();
                // open drawer
                props.toggleDrawer();
              }}
            />
          ) : null}
        </div>
        {eval(props.admin) ? (
          <Select
            style={styles.dashboardSelect}
            value={props.center}
            onChange={(e) => props.handleCenterChange(e.target.value)}
            onClose={() => setSearchText("")}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "#049c77",
                  color: "#fff",
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "pink",
                },
              },
              autoFocus: false,
            }}
            renderValue={() => props.center.toUpperCase()}
          >
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions.map((center, i) => (
              <MenuItem
                value={center}
                key={i}
                // onClick={() => props.handleCenterChange(center)}
                // style={{ background: props.center === center ? '#049c77' : '#fff', color: props.center === center ? '#fff' : '#000' }}
              >
                {center.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <p style={styles.dashboardHeaderText}>{props.center.toUpperCase()}</p>
        )}
      </div>
      <div
        style={styles.logoutWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <Badge
              badgeContent={props.notifications.length}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "red",
                },
              }}
            >
              <CircleNotificationsRoundedIcon
                color="white"
                style={{
                  fill: "#fff",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setnotificationOpen(!notificationOpen);
                  // props.setProfile(false);
                }}
              />
            </Badge>
          </div>
          <div
            className={notificationOpen ? "mounted" : "unmounted"}
            style={styles.dropdownContent}
          >
            {props.notifications.map((notification, index) => (
              <p
                style={{ color: "black" }}
                key={index}
                onClick={() => console.log("clieck")}
              >
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Job ID
                    </Typography>
                    <Typography variant="h5" component="div">
                      {notification["job_id"]}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {notification["center"]}
                    </Typography>
                    <Typography variant="body2">
                      status :
                      <br />
                      {notification["status"]}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={() => {
                        props.handleCenterChange(
                          notification["center"].match(/.{1,4}/g).join("_")
                        );
                        props.setFilterText(notification["job_id"]);
                      }}
                    >
                      navigate to case
                    </Button>
                  </CardActions>
                </Card>
              </p>
            ))}
          </div>
        </div>
        <div style={styles.dropdown}>
          <div
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <ProfileIcon
              onClick={() => {
                props.toggleDropdown();
              }}
              style={{
                fill: "#fff",
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
            />
          </div>
          <div
            className={dropdownOpen ? "mounted" : "unmounted"}
            style={styles.dropdownContent}
          >
            <p
              style={{
                ...styles.dropdownContentItem,
                cursor: "default",
                borderBlockEnd: "2px solid #0087ca",
                fontWeight: "500",
              }}
            >
              <img
                id="preview"
                className="userLogo"
                src={LogoimgSrc}
                alt="profile-pic"
              />
              {props.user}
            </p>
            <p
              className="dropActionlnk"
              style={styles.dropdownContentItem}
              onClick={props.logout}
            >
              <span className="userLogo">
                <PowerSettingsNewIcon />
              </span>
              Logout
            </p>
            {props.admin && (
              <p
                className="dropActionlnk"
                style={styles.dropdownContentItem}
                onClick={handleAddUser}
              >
                <span className="userLogo">
                  <PersonAddIcon />
                </span>
                Add User
              </p>
            )}
            <p
              className="dropActionlnk"
              style={styles.dropdownContentItem}
              onClick={props.resetPassword}
            >
              <span className="userLogo">
                <LockResetIcon />
              </span>
              Reset Password
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Report = (props) => {
  if (props.admin) {
    return (
      <td style={styles.tableBodyItem}>
        <SummarizeIcon
          style={{
            fill: "#1b4b84",
            cursor: "pointer",
          }}
          onClick={props.onSummarizeClick}
        />
      </td>
    );
  } else {
    if (props.center !== "nirman" && props.center !== "ishan" && props.center !== "bt_savani") {
      if (props.center.toLowerCase().includes("cect")) {
        return (
          <td style={styles.tableBodyItem}>
            {props.job.reportStatus === "completed" ? (
              <div>
                <SaveIcon
                  style={{
                    fill: "#1b4b84",
                    cursor: "pointer",
                  }}
                  onClick={props.onSaveClick}
                />
                <GetAppIcon
                  style={{
                    fill: "#1b4b84",
                    cursor: "pointer",
                  }}
                  onClick={props.onGetMergeClick}
                />

                {!props.job.report_sent ? (
                  <WhatsAppIcon
                    style={{
                      fill: "#1b4b84",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    onClick={props.sendWhatsapp}
                  />
                ) : (
                  <MarkChatReadIcon />
                )}
                <NoteAdd
                  style={{
                    fill: "#1b4b84",
                    cursor: "pointer",
                    marginInline: "5px",
                  }}
                  onClick={props.onMergeClick}
                />
                <Link to={`/report/${props.job.jobId}`}>
                  <EditIcon
                    style={{
                      fill: "#1b4b84",
                      cursor: "pointer",
                    }}
                  />
                </Link>
              </div>
            ) : null}
          </td>
        );
      } else {
        return (
          <td style={styles.tableBodyItem}>
            {props.job.reportStatus === "completed" ? (
              <SaveIcon
                style={{
                  fill: "#1b4b84",
                  cursor: "pointer",
                }}
                onClick={props.onSaveClick}
              />
            ) : null}
          </td>
        );
      }
    } else {
      return (
        <td style={styles.tableBodyItem}>
          {props.job.reportStatus === "completed" ? (
            <div>
              <SaveIcon
                style={{
                  fill: "#1b4b84",
                  cursor: "pointer",
                }}
                onClick={props.onSaveClick}
              />
              <Link to={`/report/${props.job.jobId}`}>
                <EditIcon
                  style={{
                    fill: "#1b4b84",
                  }}
                />
              </Link>
            </div>
          ) : null}
        </td>
      );
    }
  }
};

const Entry = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);
  console.log("entry: ", props.job);
  if (expanded) {
    console.log("expanded: ", props.job);
    return (
      <div style={styles.entryopen(props.job.status, props.job.findings)}>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Job ID</div>
          <div style={styles.entryRowValue} onClick={() => {}}>
            {props.job.jobId.substring(0, 8)}
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>From</div>
          <div style={styles.entryRowValue}>{props.job.from}</div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Patient ID</div>
          <div style={styles.entryRowValue}>{props.job.patientID}</div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Patient Name</div>
          <div style={styles.entryRowValue}>
            {props.job.patientName.substring(0, 20)}
          </div>
        </div>
        {props.center === "aster" ? (
          <div style={styles.entryRow}>
            <div style={styles.entryRowKey}>Accession Number</div>
            <div style={styles.entryRowValue}>
              {props.job.accessionNumber.substring(0, 20)}
            </div>
          </div>
        ) : null}
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Status</div>
          <div style={styles.entryRowValue}>
            <Status status={props.job.status} statusObj={props.statusObj} />
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Received At</div>
          <div style={styles.entryRowValue}>
            {new Date(props.job.receivedAt).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
              hour12: false,
            })}
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Completed At</div>
          <div style={styles.entryRowValue}>
            {props.job.completedAt
              ? new Date(props.job.completedAt).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  hour12: false,
                })
              : ""}
          </div>
        </div>

        <div
          style={styles.expandButtonWrapper}
          onClick={() => setExpanded(false)}
        >
          <ExpandLessIcon
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.entry(props.job.status, props.job.findings)}>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Job ID</div>
          <div style={styles.entryRowValue}>
            {props.job.jobId.substring(0, 8)}
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Patient ID</div>
          <div style={styles.entryRowValue}>{props.job.patientID}</div>
        </div>
        <div
          style={styles.expandButtonWrapper}
          onClick={() => setExpanded(true)}
        >
          <ExpandMoreIcon
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      </div>
    );
  }
};

const Entries = (props) => {
  // console.log('entries', props);
  // 10 false
  const [expanded, setExpanded] = React.useState(Array(10).fill(false));
  console.log("jobs", props.jobs);
  return (
    <div style={styles.entries}>
      {props.jobs.map((job, index) => (
        <Entry
          key={index}
          job={job}
          center={props.center}
          statusObj={props.statusObj}
          expanded={expanded[index]}
        />
      ))}
    </div>
  );
};

export default function Jobs(props) {
  // get navigate state

  const location = useLocation();
  console.log("location", location);
  const locationQuery = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [showAll, setShowAll] = useState(
    (locationQuery.get("showAll") && locationQuery.get("showAll") === "true") ||
      true
  );
  const [jobUpdated, setJobUpdated] = useState(0);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [jobId, setJobId] = useState("");
  const [newFeedback, setNewFeedback] = useState("");
  const feedbackValues = [
    "Cyst",
    "Mass",
    "Bladder wall thickening",
    "Single kidney",
    "Horseshoe kidney",
    "Ectopic kidney",
    "Extra renal pelvis",
    "Severe HUN ",
    "Prostate calculus",
    "Urethral calculus",
    "Peripheral calculus",
    "Renal artery calcifications",
    "Phleboliths",
    "Cystic calcification",
    "Malrotated kidney",
    "Duplex Ureter",
    "Diverticulum",
    "Staghorn calculus",
    "FN in kidney",
    "FN in ureter ",
    "FN in bladder",
    "FP in kidney",
    "FP in ureter",
    "FP in bladder",
    "More than 2 kidneys",
    "No bladder",
    "bowel artifact",
    "beam hardening artifact/streak artifact",
    "separate calculi clubbed as 1 calculus due to region growing",
    "poor kub mask",
    "incorrect location",
    "atrophic kidney",
    "bladder mass",
    "air foci",
    "cortical irregularity"
  ];
  const [feedbackSelected, setfeedbackSelected] = useState([]);

  const [Filter, setFilter] = useState("Filter by text");
  const [feedbacks, setFeedbacks] = useState([]);
  const [page, setPage] = useState(
    (locationQuery.get("page") && parseInt(locationQuery.get("page"))) || 0
  );
  const [filterText, setFilterText] = useState(
    locationQuery.get("filterText") || ""
  );
  const [filterFeedbackText, setFilterFeedbackText] = useState(
    locationQuery.get("filterFeedbackText") || ""
  );
  const [startDate, setStartDate] = useState(
    locationQuery.get("startDate") || "1970-01-01"
  );
  const [endDate, setEndDate] = useState(
    locationQuery.get("endDate") || "2100-01-01"
  );
  const [updatedJob, setUpdatedJob] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteJob, setDeleteJob] = useState({});
  const [stopJob, setStopJob] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [stopModalOpen, setStopModalOpen] = useState(false);

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportConfigs, setReportConfigs] = useState([]);
  const [reportsendModalOpen, setReportsendModalOpen] = useState(false);
  const [reportconfigid, setreportconfigid] = useState("");
  const [WhatsappNum, setWhatsappNum] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [resSend, setresSend] = useState("");
  const [loading, setLoading] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastAnchorEl, setToastAnchorEl] = useState(null);
  const [toastPlacement, setToastPlacement] = useState("");

  const [warningsOpen, setWarningsOpen] = useState(false);
  const [errorsOpen, setErrorsOpen] = useState(false);

  const [sequencesModalOpen, setSequencesModalOpen] = useState(false);

  const [metadata, setMetadata] = React.useState({});
  const [sequence, setSequence] = React.useState("");
  const [selectedSequence, setSelectedSequence] = React.useState("");
  const [imgSrc, setImgSrc] = React.useState("");

  const [baseURL, setBaseURL] = useState("");
  const [baseURLAPI, setBaseURLAPI] = useState("");
  const [ohifURL, setOhifURL] = useState("");
  const [center, setCenter] = useState("");
  const [admin, setAdmin] = useState(false);

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [mobile, setMobile] = useState(false);

  const [loggedIn, setLoggedIn] = useState(true);

  const [notifications, setNotifications] = useState([]);

  const [socket, setSocket] = useState(null);

  const [mergeModal, setMergeModal] = useState(false);

  const [fileObjects, setFileObjects] = useState([]);

  console.log(
    location,
    locationQuery.get("page"),
    locationQuery.get("page") || 1,
    page,
    "location"
  );
  const DialogTitleMerge = () => (
    <>
      <span>Merge PDF report file</span>
      <IconButton
        style={{ right: "12px", top: "8px", position: "absolute" }}
        onClick={() => setMergeModal(false)}
      >
        <Close />
      </IconButton>
    </>
  );

  React.useEffect(() => {
    console.log("getting center and admin");
    // get center from local storage
    const center = localStorage.getItem("center");
    const admin = localStorage.getItem("admin");

    setCenter(center);
    setAdmin(admin === "true");

    if (!center || !admin) {
      setLoggedIn(false);
    }

    console.log(center, admin, "center and admin");

    setBaseURL(inferURLs[center]);
    setBaseURLAPI(apiURLs[center]);
    setOhifURL(ohifURLs[center]);

    window.addEventListener("resize", () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });

    // close dropdown on click outside
    document.body.addEventListener("click", (e) => {
      console.log(e.target, !e.target.id, "e.target.id", "click");
      if (!e.target.id || e.target.id !== "profileDropdown") {
        console.log("setting profile dropdown to false");
        setProfileDropdownOpen(false);
      }
    });
  }, []);

  React.useEffect(() => {
    // on change dimensions, check if width is less than 600px
    if (dimensions.width < 600) {
      // if yes remove table columns and allow them to be expanded
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [dimensions]);

  useEffect(() => {
    let socket = io("https://inference1.urologiq.ai", {
      path: "/notifications/socket.io",
      // transports: ['websocket'],
    });
    console.log(socket, "socket");
    setSocket(socket);

    return () => {
      console.log("disconnecting socket");
      socket.disconnect();
    };
  }, []);

  React.useEffect(() => {
    if (!socket) return;
    // function playSound(url) {
    //   const audio = new Audio(url);
    //   audio.play();
    // }
    socket.on("case_notification", (data) => {
      console.log(data, "data");
      setNotifications((notifications) => [
        ...notifications,
        JSON.parse(data.notification),
      ]);
      // navigator.mediadevices.getusermedia({ audio: true });
      // play();
    });
  }, [socket]);

  console.log(notifications, "notifications");

  const onGetJobsError = (err) => {
    if (admin) {
      // just display the error message and ask to login again
      console.log(err, "err");
      setToastMessage(err.message + ". Please login again.");
      setToastOpen(true);
      setToastAnchorEl(document.getElementById("jobsTable"));
      setToastPlacement("bottom");
      setTimeout(() => {
        setToastOpen(false);
        setToastPlacement("");
      }, 3000);
    } else {
      // if not admin, then the token has expired
      // redirect to login page
      setLoggedIn(false);
    }
  };

  React.useEffect(() => {
    console.log(baseURL);
    if (baseURL !== "") {
      console.log("getting jobs");
      setLoading(true);
      setJobs([]);
      const token = admin
        ? localStorage.getItem(`token-${center}`)
        : localStorage.getItem("token");
      axios
        .get(
          `${baseURL}/jobs?show_all=${showAll}&page=${page}&text_filter=${filterText}&feedback=${filterFeedbackText}&start_date=${startDate}&end_date=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setJobs(res.data.jobs);
        })
        .catch((err) => {
          onGetJobsError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    showAll,
    jobUpdated,
    page,
    filterText,
    filterFeedbackText,
    baseURL,
    startDate,
    endDate,
  ]);

  const handleCenterChange = (center) => {
    console.log(center, "center", inferURLs[center], apiURLs[center]);
    setCenter(center);
    setBaseURL(inferURLs[center]);
    setBaseURLAPI(apiURLs[center]);
    setOhifURL(ohifURLs[center]);
    localStorage.setItem("center", center);
  };

  const refreshImage = (jobId, sequence) => {
    // set loading image source
    setImgSrc("https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif");
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    axios
      .get(`${baseURL}/sample_sequence_images/${jobId}/${sequence}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // base64
        setImgSrc("data:image/png;base64," + res.data.image);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getConfigs = (jobId) => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    axios
      .get(`${baseURLAPI}/configs_sent?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("configs", res.data);
        let configs_sent = res.data.configs;
        for (let i in configs_sent) {
          let gmtDate = new Date(configs_sent[i].config_time);
          let istDate = gmtDate.toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          configs_sent[i].config_time = istDate;
        }
        console.log("IST time configs", configs_sent);
        setReportConfigs(configs_sent);
        setReportModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let inputRef = null;

  function searchquery(value) {
    if (Filter == "Filter by text") {
      setFilterText(value);
      setFilterFeedbackText("");
      navigate(
        `/jobs?showAll=${showAll}&page=${page}&filterText=${value}&startDate=${startDate}&endDate=${endDate}`
      );
    } else {
      setFilterFeedbackText(value);
      navigate(
        `/jobs?showAll=${showAll}&page=${page}&filterFeedbackText=${value}&startDate=${startDate}&endDate=${endDate}`
      );
    }
  }

  if (baseURL === "") {
    return (
      // show loading
      <div style={styles.wrapper}>
        <Header
          user={localStorage.getItem("username")}
          admin={admin}
          handleCenterChange={handleCenterChange}
          logout={() => onLogout({ admin: admin, center: center })}
          center={center}
          dropdownOpen={profileDropdownOpen}
          notifications={notifications}
          toggleDropdown={() => setProfileDropdownOpen(!profileDropdownOpen)}
          resetPassword={() => navigate("/password_reset")}
          toggleDrawer={() => {
            console.log("toggle drawer", drawerOpen);
            setDrawerOpen(!drawerOpen);
          }}
        />
        <CircularProgress
          style={{ color: "#1b4b84", margin: "auto", marginTop: "100px" }}
        />
      </div>
    );
  }

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  let videoCenters = ["ozone", "mediscan"];

  console.log("jobs", jobs);

  return (
    <div style={styles.wrapper}>
      <Header
        user={localStorage.getItem("username")}
        admin={admin}
        handleCenterChange={handleCenterChange}
        logout={() => onLogout({ admin: admin, center: center })}
        center={center}
        dropdownOpen={profileDropdownOpen}
        setFilterText={searchquery}
        notifications={notifications}
        toggleDropdown={() => setProfileDropdownOpen(!profileDropdownOpen)}
        resetPassword={() => navigate("/password_reset")}
        toggleDrawer={() => {
          console.log("toggle drawer", drawerOpen);
          setDrawerOpen(!drawerOpen);
        }}
      />
      <div style={styles.container}>
        {admin ? (
          <div style={drawerOpen ? styles.drawer : styles.closedDrawer}>
            <img
              src={logo}
              style={{ width: "100%", height: "auto", marginBottom: "20px" }}
            />
            <div style={styles.drawerContent}>
              <div
                className={
                  location.pathname === "/jobs"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/jobs")}
              >
                <ListIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Jobs
                </p>
              </div>
              <div
                className={
                  location.pathname === "/jobsall"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/admin/jobsall")}
              >
                <ListIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  All Centers Jobs
                </p>
              </div>
              {/* <div
                className={
                  location.pathname === "/tasks"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/admin/tasks")}
              >
                <ListIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Tasks List
                </p>
              </div> */}
              <div
                className={
                  location.pathname === "/analytics"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/analytics")}
              >
                <BarChartIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Analytics
                </p>
              </div>
              <div
                className={
                  location.pathname === "/user-management"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/admin/user-management")}
              >
                <PersonAddIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  User Management
                </p>
              </div>
              <div
                className={
                  location.pathname === "/gatewaystatus"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/admin/gatewaystatus")}
              >
                <BarChartIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Gateway Status
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div style={styles.content}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#1b4b84",
              color: "#fff",
              marginBlock: "10px",
            }}
            onClick={() => {
              navigate("/admin/upload");
            }}
          >
            Upload New Job
          </Button>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <div>
              <Switch
                checked={showAll}
                style={{
                  alignItems: "left",
                  fill: "#1b4b84",
                }}
                onChange={(e) => {
                  // console.log(val);
                  setShowAll(e.target.checked);
                  navigate(
                    `/jobs?showAll=${e.target.checked}&page=${page}&textFilter=${filterText}`
                  );
                  // getData();
                }}
              />{" "}
              Show All
            </div>
            <div>
              <Button
                variant="text"
                disabled={page <= 0}
                style={{
                  color: page <= 0 ? "#888" : "#1b4b84",
                }}
                onClick={() => {
                  console.log("previous page");
                  if (page > 0) {
                    setPage(page - 1);
                    navigate(
                      `/jobs?showAll=${showAll}&page=${
                        page - 1
                      }&filterText=${filterText}`
                    );
                  }
                }}
              >
                <ArrowBackIcon />
              </Button>
              <span style={{ color: "black" }}>{parseInt(page) + 1}</span>
              <Button
                variant="text"
                disabled={jobs.length < 10}
                style={{
                  color: jobs.length < 10 ? "#888" : "#1b4b84",
                }}
                onClick={() => {
                  console.log("next page");
                  setPage(page + 1);
                  navigate(
                    `/jobs?showAll=${showAll}&page=${
                      page + 1
                    }&filterText=${filterText}&startDate=${startDate}&endDate=${endDate}`
                  );
                }}
              >
                <ArrowForwardIcon />
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0, padding: 0, color: "#1b4b84" }}>
                Start Date
              </p>
              <input
                type="date"
                onChange={(e) => {
                  console.log(e.target.value);
                  setStartDate(e.target.value);
                  navigate(
                    `/jobs?showAll=${showAll}&page=${page}&filterText=${filterText}&startDate=${e.target.value}&endDate=${endDate}`
                  );
                }}
                style={styles.filterDate}
              />
              <p style={{ margin: 0, padding: 0, color: "#1b4b84" }}>
                End Date
              </p>
              <input
                type="date"
                onChange={(e) => {
                  console.log(e.target.value);
                  setEndDate(e.target.value);
                  navigate(
                    `/jobs?showAll=${showAll}&page=${page}&filterText=${filterText}&startDate=${startDate}&endDate=${e.target.value}`
                  );
                }}
                style={styles.filterDate}
              />
            </div>
            <div className={mobile && "mobileMaxWidth"}>
              <Select
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                // style={styles.select}
                value={Filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                {["Filter by text", "Filter by feedback"].map((key, index) => (
                  <MenuItem key={index} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              <input
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (Filter == "Filter by text") {
                      setFilterText(e.target.value);
                      setFilterFeedbackText("");
                      navigate(
                        `/jobs?showAll=${showAll}&page=${page}&filterText=${e.target.value}&startDate=${startDate}&endDate=${endDate}`
                      );
                    } else {
                      setFilterFeedbackText(e.target.value);
                      navigate(
                        `/jobs?showAll=${showAll}&page=${page}&filterFeedbackText=${e.target.value}&startDate=${startDate}&endDate=${endDate}`
                      );
                    }
                  }
                }}
                ref={(ref) => {
                  inputRef = ref;
                }}
                placeholder="Filter Jobs"
                className={mobile && "mobileInputWidth"}
              />
              <Button
                variant="text"
                onClick={() => {
                  console.log(inputRef.value, "value");
                  searchquery(inputRef.value);
                }}
              >
                <SearchIcon />
              </Button>
            </div>
          </div>
          {mobile ? (
            <div
              style={{
                overflowX: "scroll",
                width: "100%",
                height: "100%",
                paddingBottom: "30px",
              }}
            >
              <Entries
                jobs={jobs}
                center={center}
                statusObj={admin ? styles.statusObjAdmin : styles.statusObj}
              />
            </div>
          ) : (
            <div className="table-wrapper">
              <Table id="jobsTable" style={styles.table(window.innerHeight)}>
                <TableHead>
                  <TableRow style={{ background: "#1b4b84" }}>
                    <th
                      style={{
                        ...styles.smallWidth,
                        ...styles.tableHeaderItem,
                      }}
                    >
                      Sent to Ref. Dr.
                    </th>
                    <th style={styles.tableHeaderItem}>Job ID</th>
                    <th style={styles.tableHeaderItem}>From</th>
                    <th style={styles.tableHeaderItem}>Patient ID</th>
                    <th style={styles.tableHeaderItem}>Patient Name</th>
                    {center === "aster" ? (
                      <>
                        <th style={styles.tableHeaderItem}>Study ID</th>
                        <th style={styles.tableHeaderItem}>Accession Number</th>
                      </>
                    ) : null}
                    <th style={styles.tableHeaderItem}>Status</th>
                    <th style={styles.tableHeaderItem}>Recieved At</th>
                    <th style={styles.tableHeaderItem}>Completed At</th>
                    {admin ? (
                      <th style={styles.tableHeaderItem}>Verified At</th>
                    ) : null}
                    {admin ? (
                      <th style={styles.tableHeaderItem}>Sequences</th>
                    ) : null}
                    {admin || center == "cect" ? (
                      <th style={styles.tableHeaderItem}>View</th>
                    ) : null}
                    {/* {!admin || center == "cect" ? (
                      <th style={styles.tableHeaderItem}>Download Study</th>
                    ) : null} */}
                    {admin ? (
                      <th style={styles.tableHeaderItem}>Annotate</th>
                    ) : null}
                    <th style={styles.tableHeaderItem}>Report</th>
                    <th style={styles.tableHeaderItem}>3D</th>
                    <th style={styles.tableHeaderItem}>Feedback</th>
                    {admin ? (
                      <th style={styles.tableHeaderItem}>Warnings</th>
                    ) : null}
                    {admin ? (
                      <th style={styles.tableHeaderItem}>Actions</th>
                    ) : null}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {jobs.map((job) => (
                    <tr
                      key={job.jobId}
                      style={styles.tableRow(job.status, job.findings)}
                    >
                      <td style={styles.smallWidth}>
                        <Checkbox
                          checked={job.sentToReferring === "yes"}
                          // style={{
                          //     color: job.sentToReferring === 'yes' ? '#1b4b84' : 'white',
                          // }}
                          onChange={(e) => {
                            console.log(e);
                            const formData = new FormData();
                            formData.append("job_id", job.jobId);
                            formData.append(
                              "update_key",
                              "sent_to_referring_doctor"
                            );
                            // formData.append('update_val', 'yes');
                            formData.append(
                              "update_val",
                              e.target.checked ? "yes" : "no"
                            );
                            axios({
                              method: "POST",
                              url: `${baseURLAPI}/job_update`,
                              data: formData,
                            })
                              .then((res) => {
                                console.log("updated....", res);
                                // getData();
                                setJobUpdated(
                                  (prevJobUpdated) => prevJobUpdated + 1
                                );
                              })
                              .catch((err) => {
                                console.log("error: ", err);
                              });
                          }}
                        />
                      </td>
                      {/* <td style={styles.tableBodyItem}>{job.jobId}</td> */}
                      <td
                        style={styles.tableBodyItem}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.title = job.jobId;
                        }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(job.jobId);
                          setToastMessage("Job ID copied to clipboard");
                          setToastOpen(true);
                          setToastPlacement("right-end");
                          setToastAnchorEl(e.target);
                          setTimeout(() => {
                            setToastOpen(false);
                            setToastMessage("");
                            setToastPlacement("");
                          }, 2000);
                        }}
                      >
                        {/* // show only 8 characters of job id */}
                        {job.jobId.substring(0, 8)}
                      </td>
                      <td style={styles.tableBodyItem}>{job.dataFrom}</td>
                      <td style={styles.tableBodyItem}>{job.patientId}</td>
                      <td style={styles.tableBodyItem}>{job.patientName}</td>
                      {center === "aster" ? (
                        <>
                          <td
                            style={styles.tableBodyItem}
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                              e.target.title = job.studyId;
                            }}
                            onClick={(e) => {
                              navigator.clipboard.writeText(job.studyId);
                              setToastMessage("Study ID copied to clipboard");
                              setToastOpen(true);
                              setToastPlacement("right-end");
                              setToastAnchorEl(e.target);
                              setTimeout(() => {
                                setToastOpen(false);
                                setToastMessage("");
                                setToastPlacement("");
                              }, 2000);
                            }}
                          >
                            {job.studyId.substring(0, 16)}
                          </td>
                          <td style={styles.tableBodyItem}>
                            {job.accessionNumber}
                          </td>
                        </>
                      ) : null}
                      <td style={styles.tableBodyItem}>
                        {admin ? (
                          <Status
                            status={job.status}
                            statusObj={styles.statusObjAdmin}
                          />
                        ) : (
                          <Status
                            status={
                              job.reportStatus ||
                              (job.status.toLowerCase() === "queued" &&
                                job.status) ||
                              (job.status.toLowerCase() === "failed" &&
                                job.status) ||
                              "Processing"
                            }
                            statusObj={styles.statusObj}
                          />
                        )}
                      </td>
                      <td style={styles.tableBodyItem}>
                        {new Date(job.receivedAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                          hour12: false,
                        })}
                      </td>
                      <td style={styles.tableBodyItem}>
                        {admin
                          ? job.completedAt === null
                            ? "NA"
                            : new Date(job.completedAt).toLocaleString(
                                "en-IN",
                                { timeZone: "Asia/Kolkata", hour12: false }
                              )
                          : job.verifiedAt != null
                          ? new Date(job.verifiedAt).toLocaleString("en-IN", {
                              timeZone: "Asia/Kolkata",
                              hour12: false,
                            })
                          : "NA"}
                      </td>
                      {admin ? (
                        <td style={styles.tableBodyItem}>
                          {job.verifiedAt != null
                            ? new Date(job.verifiedAt).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                                hour12: false,
                              })
                            : "NA"}
                        </td>
                      ) : null}
                      {admin ? (
                        <td style={styles.tableBodyItem}>
                          <VisibilityIcon
                            style={{
                              fill: "#1b4b84",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const token = admin
                                ? localStorage.getItem(`token-${center}`)
                                : localStorage.getItem("token");
                              axios
                                .get(
                                  `${baseURLAPI}/admin/job_sequences/${job.jobId}`,
                                  {
                                    headers: {
                                      Authorization: "Bearer " + token,
                                    },
                                  }
                                )
                                .then((res) => {
                                  const response = res.data;
                                  console.log("response", response);
                                  setMetadata(response.metadata);
                                  setSequence(response.selected_sequence);
                                  setSelectedSequence(
                                    response.selected_sequence
                                  );
                                  setJobId(job.jobId);
                                  refreshImage(
                                    job.jobId,
                                    response.selected_sequence
                                  );
                                })
                                .catch((err) => {
                                  console.log(err);
                                });

                              setSequencesModalOpen(true);
                            }}
                          />
                        </td>
                      ) : null}
                      {admin || center == "cect" ? (
                        <>
                          <td style={styles.tableBodyItem}>
                            <OpenInNewIcon
                              style={{
                                fill: "#1b4b84",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // first get viewer token
                                const token = admin
                                  ? localStorage.getItem(`token-${center}`)
                                  : localStorage.getItem("token");
                                axios
                                  .get(`${baseURLAPI}/viewer-token/make`, {
                                    headers: {
                                      Authorization: "Bearer " + token,
                                    },
                                  })
                                  .then((res) => {
                                    const response = res.data;
                                    console.log("response", response);
                                    // then open viewer

                                    let link = document.createElement("a");
                                    // let url = `https://urologic.biocliq.ai/aarthi/ohif_viewer/viewer/${job.studyId}`;
                                    // console.log("ohifURL: ", ohifURL)
                                    // let url = `${ohifURL}/${job.studyId}`;
                                    let url = `https://inference4.urologiq.ai/urologiq_test/ohif_viewer/viewer/${job.studyId}?center=${center}&viewerToken=${response.token}`;
                                    link.href = url;
                                    link.click();
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                            />
                          </td>
                        </>
                      ) : null}
                      {/* {center == "cect" && (
                        <td style={styles.tableBodyItem}>
                          <a href={job.link} download>
                            <SaveIcon
                              style={{
                                fill: "#1b4b84",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </td>
                      )} */}
                      {admin ? (
                        <td style={styles.tableBodyItem}>
                          <OpenInNewIcon
                            style={{
                              fill: "#1b4b84",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(`/view/${job.jobId}`);
                            }}
                          />
                        </td>
                      ) : null}

                      <Report
                        job={job}
                        admin={admin}
                        center={center}
                        onSummarizeClick={() => {
                          setJobId(job.jobId);
                          const token = admin
                            ? localStorage.getItem(`token-${center}`)
                            : localStorage.getItem("token");
                          getConfigs(job.jobId);
                        }}
                        onMergeClick={() => {
                          setJobId(job.jobId);
                          setMergeModal(true);
                        }}
                        onSaveClick={() => {
                          const token = admin
                            ? localStorage.getItem(`token-${center}`)
                            : localStorage.getItem("token");

                          axios({
                            method: "GET",
                            url: `${baseURL}/report/${job.jobId}`,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                            responseType: "arraybuffer",
                          })
                            .then((res) => {
                              // download file
                              console.log("res", res);
                              let blob = new Blob([res.data], {
                                type: "application/pdf",
                              });
                              let url = window.URL.createObjectURL(blob);
                              let link = document.createElement("a");
                              link.href = url;
                              link.download = `${job.jobId}.pdf`;
                              link.click();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        onGetMergeClick={() => {
                          const token = admin
                            ? localStorage.getItem(`token-${center}`)
                            : localStorage.getItem("token");

                          axios({
                            method: "GET",
                            url: `${baseURL}/report_merged/${job.jobId}`,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                            responseType: "arraybuffer",
                          })
                            .then((res) => {
                              // download file
                              console.log("res", res);
                              let blob = new Blob([res.data], {
                                type: "application/pdf",
                              });
                              let url = window.URL.createObjectURL(blob);
                              let link = document.createElement("a");
                              link.href = url;
                              link.download = `${job.jobId}.pdf`;
                              link.click();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        sendWhatsapp={() => {
                          const token = admin
                            ? localStorage.getItem(`token-${center}`)
                            : localStorage.getItem("token");
                          const data = {
                            job_id: job.jobId,
                          };
                          axios
                            .post(baseURLAPI + "/send_report_cect", data, {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            })
                            .then((res) => {
                              setShowAll(!showAll);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      />
                      <td style={styles.tableBodyItem}>
                        {admin || job.reportStatus === "completed" ? (
                          <Link
                            to={`/render/${job.jobId}`}
                            style={{
                              display: "flex",
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                            // state={{
                            //     patientName: job.patientName,
                            // }}
                          >
                            <ViewInArIcon style={{ fill: "#1b4b84" }} />
                            {admin ? (
                              <span style={styles.badge}>{job.qrVisitors}</span>
                            ) : null}
                          </Link>
                        ) : null}
                      </td>
                      <td style={styles.tableBodyItem}>
                        <Button
                          onClick={() => {
                            const token = admin
                              ? localStorage.getItem(`token-${center}`)
                              : localStorage.getItem("token");
                            axios
                              .get(
                                `${baseURLAPI}/feedback?job_id=${job.jobId}`,
                                {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                }
                              )
                              .then((res) => {
                                console.log("feedbacks", res);
                                setFeedbacks(res.data.feedback);
                                setFeedbackModalOpen(true);
                                setJobId(job.jobId);
                              })
                              .catch((err) => {
                                console.log("err", err);
                              });
                          }}
                        >
                          <CommentIcon />
                        </Button>
                      </td>
                      {admin ? (
                        <td
                          style={styles.tableBodyItem}
                          onMouseOver={(e) => {
                            console.log("job status", job.status);
                            if (job.status !== "Failed") {
                              if (
                                Object.values(job.warnings || {}).reduce(
                                  (a, b) => a + b.length,
                                  0
                                ) > 0
                              ) {
                                setJobId(job.jobId);
                                setToastMessage(job.warnings);
                                setWarningsOpen(true);
                                setToastAnchorEl(e.currentTarget);
                                setTimeout(() => {
                                  setWarningsOpen(false);
                                  setToastMessage("");
                                }, 2000);
                              }
                              console.log("no warnings");
                            } else {
                              // set cursor to pointer
                              e.currentTarget.style.cursor = "pointer";
                              console.log("getting errors");
                              const target = e.currentTarget;
                              // get errors from server
                              const token = admin
                                ? localStorage.getItem(`token-${center}`)
                                : localStorage.getItem("token");
                              axios
                                .get(
                                  `${baseURLAPI}/errors?job_id=${job.jobId}`,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  console.log(res.data.errors, "errors");
                                  setJobId(job.jobId);
                                  setErrorsOpen(true);
                                  setToastMessage(res.data.errors);
                                  console.log(
                                    e.currentTarget,
                                    "current target"
                                  );
                                  setToastAnchorEl(target);
                                  setTimeout(() => {
                                    setErrorsOpen(false);
                                    setToastMessage("");
                                  }, 8000);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }
                          }}
                          onClick={(e) => {
                            if (job.status === "Failed") {
                              // download log file
                              const token = admin
                                ? localStorage.getItem(`token-${center}`)
                                : localStorage.getItem("token");
                              axios({
                                method: "GET",
                                url: `${baseURLAPI}/log?job_id=${job.jobId}`,
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                                // responseType: "arraybuffer",
                              })
                                .then((res) => {
                                  // download file
                                  console.log("res", res);

                                  let blob = new Blob([res.data], {
                                    type: "text/plain",
                                  });
                                  let url = window.URL.createObjectURL(blob);
                                  let link = document.createElement("a");

                                  link.href = url;
                                  link.download = `${job.jobId}.log`;
                                  link.click();
                                })
                                .catch((err) => {
                                  console.log("err", err);
                                });
                            }
                          }}
                        >
                          {job.status === "Finished"
                            ? Object.values(job.warnings || {}).reduce(
                                (a, b) => a + b.length,
                                0
                              )
                            : null}
                          {job.status === "Failed" ? (
                            <ErrorIcon style={{ fill: "#1b4b84" }} />
                          ) : null}
                        </td>
                      ) : null}
                      {admin ? (
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            maxHeight: "30px",
                            borderWidth: "0px",
                            borderStyle: "none",
                            padding: "3px",
                            minWidth: "80px",
                          }}
                        >
                          {/* <EditIcon
                            style={{ color: "green", cursor: "pointer" }}
                            onClick={() => {
                              console.log("edit job", job);
                              setUpdatedJob(job);
                              setEditModalOpen(true);
                            }}
                          /> */}

                          <DeleteForever
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              setDeleteJob(job);
                              setDeleteModalOpen(true);
                            }}
                          />
                          {job.status != "finished" && (
                            <StopCircleIcon
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => {
                                setStopJob(job);
                                setStopModalOpen(true);
                              }}
                            />
                          )}
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ color: "#1b4b84", margin: "auto", marginTop: "100px" }}
              />
            </div>
          ) : null}
          <Modal
            open={feedbackModalOpen}
            onClose={() => {
              setFeedbackModalOpen(false);
              setNewFeedback("");
            }}
          >
            <div style={styles.feedbackModal}>
              Feedbacks
              {feedbacks.length > 0 ? (
                <div style={{ width: "100%", height: "70%" }}>
                  {feedbacks.length} feedbacks found
                  <div className="feedbacks">
                    {feedbacks.map((feedback, index) => {
                      return (
                        <Feedback
                          key={index}
                          index={index + 1}
                          feedback={feedback.feedback}
                          feedbackBy={feedback.feedbackBy}
                          feedbackDate={feedback.feedbackDate}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <p>No feedbacks found</p>
              )}
              <Select
                closeMenuOnSelect={false}
                style={{
                  width: "200px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                // components={animatedComponents}
                value={feedbackSelected}
                renderValue={(selected) => {
                  console.log(selected);
                  if (selected.length === 0) {
                    return <em>Select feedback</em>;
                  }
                  return selected.join(", ");
                }}
                multiple
                options={feedbackValues}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 1000
                    }
                  }
                }}
              >
                <MenuItem value="all" sx={{ display: "inline-flex", width: "33%" }}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        feedbackValues.length === feedbackSelected.length
                      }
                      onChange={(e) => {
                        console.log(e, "clicked icon");
                        e.stopPropagation();
                        if (feedbackSelected.length === feedbackValues.length) {
                          // remove all from selected
                          setfeedbackSelected([]);
                        } else {
                          setfeedbackSelected(feedbackValues);
                        }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="all" />
                </MenuItem>
                {feedbackValues.map((center, index) => {
                  return (
                    <MenuItem key={index} value={center} sx={{ display: "inline-flex", width: "33%" }}>
                      <ListItemIcon>
                        <Checkbox
                          checked={
                            feedbackSelected.indexOf(center) > -1 ||
                            feedbackSelected.indexOf("all") > -1
                          }
                          onChange={(e) => {
                            console.log(e, "clicked icon");
                            e.stopPropagation();
                            if (
                              feedbackSelected.length ===
                                Object.keys(apiURLs).length ||
                              feedbackSelected.indexOf("all") > -1
                            ) {
                              // remove all from selected
                              let newfeedbackSelected = feedbackValues.filter(
                                (c) => c !== center
                              );
                              setfeedbackSelected(newfeedbackSelected);
                            } else {
                              // check if center is already selected
                              if (feedbackSelected.indexOf(center) > -1) {
                                let newfeedbackSelected =
                                  feedbackSelected.filter((c) => c !== center);
                                setfeedbackSelected(newfeedbackSelected);
                              } // add center to selected
                              else
                                setfeedbackSelected([
                                  ...feedbackSelected,
                                  center,
                                ]);
                            }
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={center} />
                    </MenuItem>
                  );
                })}
              </Select>
              {/* <Select
                style={styles.select}
                value={newFeedback}
                onChange={(e) => setNewFeedback(e.target.value)}
              >
                {[
                  "Cyst",
                  "Mass",
                  "Bladder wall thickening",
                  "Single kidney",
                  "Horseshoe kidney",
                  "Ectopic kidney",
                  "Extra renal pelvis",
                  "Severe HUN ",
                  "Prostate calculus",
                  "Urethral calculus",
                  "Peripheral calculus",
                  "Renal artery calcifications",
                  "Phleboliths",
                  "Cystic calcification",
                  "Malrotated kidney",
                  "Duplex Ureter",
                  "Diverticulum",
                  "Staghorn calculus",
                  "FN in kidney",
                  "FN in ureter ",
                  "FN in bladder",
                  "FP in kidney",
                  "FP in ureter",
                  "FP in bladder",
                  "More than 2 kidneys",
                  "No bladder",
                  "bowel artifact",
                  "beam hardening artifact/streak artifact",
                  "separate calculi clubbed as 1 calculus due to region growing",
                  "poor kub mask",
                  "incorrect location",
                ].map((key, index) => (
                  <MenuItem key={index} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select> */}
              <TextField
                label="feedback"
                value={feedbackSelected.join(",")}
                onChange={(e) => setfeedbackSelected(e.target.value.split(","))}
              ></TextField>
              <Button
                onClick={() => {
                  const token = admin
                    ? localStorage.getItem(`token-${center}`)
                    : localStorage.getItem("token");
                  axios
                    .post(
                      `${baseURLAPI}/feedback`,
                      {
                        job_id: jobId,
                        feedback: feedbackSelected.join(","),
                        // email: props.currentUser.email,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("res", res);
                      axios
                        .get(`${baseURLAPI}/feedback?job_id=${jobId}`, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        })
                        .then((res) => {
                          console.log("feedbacks", res);
                          setFeedbacks(res.data.feedback);
                        })
                        .catch((err) => {
                          console.log("err", err);
                        });
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }}
              >
                Add
              </Button>
            </div>
          </Modal>
          <Modal
            open={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
            }}
          >
            <div style={styles.modal}>
              <h3>Update Job</h3>
              <div style={styles.modalBody}>
                <div style={styles.modalBodyItem}>
                  <label>Job Id</label>
                  <input type="text" value={jobId} disabled />
                </div>
                <div style={styles.modalBodyItem}>
                  <label>Patient Id</label>
                  <input
                    type="text"
                    value={updatedJob.patientId}
                    onChange={(e) => {
                      setUpdatedJob((prevUpdatedJob) => {
                        return {
                          ...prevUpdatedJob,
                          patientId: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div style={styles.modalBodyItem}>
                  <label>Patient Name</label>
                  <input
                    type="text"
                    value={updatedJob.patientName}
                    onChange={(e) => {
                      setUpdatedJob((prevUpdatedJob) => {
                        return {
                          ...prevUpdatedJob,
                          patientName: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div style={styles.modalBodyItem}>
                  <label>Status</label>
                  <input
                    value={updatedJob.status}
                    onChange={(e) => {
                      setUpdatedJob((prevUpdatedJob) => {
                        return {
                          ...prevUpdatedJob,
                          status: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div style={styles.modalBodyItem}>
                  <label>Received At</label>
                  <input
                    type="datetime-local"
                    value={updatedJob.receivedAt}
                    onChange={(e) => {
                      setUpdatedJob((prevUpdatedJob) => {
                        return {
                          ...prevUpdatedJob,
                          receivedAt: new Date(e.target.value).toUTCString(),
                        };
                      });
                    }}
                  />
                </div>
                <div style={styles.modalBodyItem}>
                  <label>Completed At</label>
                  <input
                    type="datetime-local"
                    value={updatedJob.completedAt}
                    onChange={(e) => {
                      setUpdatedJob((prevUpdatedJob) => {
                        return {
                          ...prevUpdatedJob,
                          completedAt: new Date(e.target.value).toUTCString(),
                        };
                      });
                    }}
                  />
                </div>

                <Button
                  onClick={() => {
                    const formData = new FormData();
                    formData.append("job_id", updatedJob.jobId);
                    formData.append("patient_id", updatedJob.patientId);
                    formData.append("patient_name", updatedJob.patientName);
                    formData.append("status", updatedJob.status);
                    formData.append("received_at", updatedJob.receivedAt);
                    formData.append("completed_at", updatedJob.completedAt);
                    const token = admin
                      ? localStorage.getItem(`token-${center}`)
                      : localStorage.getItem("token");
                    axios
                      .post(`${baseURLAPI}/admin/job_update`, formData, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      .then((res) => {
                        console.log(res);
                        setJobUpdated((prevJobUpdated) => prevJobUpdated + 1);
                        setEditModalOpen(false);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  style={styles.modalButton}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            open={stopModalOpen}
            onClose={() => {
              setStopModalOpen(false);
            }}
          >
            <div style={styles.deleteModal}>
              <h3>Stop Job</h3>
              <div style={styles.modalBody}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label style={{ fontWeight: "700", fontSize: "14pt" }}>
                    Are you sure you want to stop this job?
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      width: "98%",
                    }}
                  >
                    <div>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "700",
                          marginRight: "5px",
                        }}
                      >
                        Job Id :{" "}
                      </label>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "400",
                        }}
                      >
                        {stopJob.jobId}
                      </label>
                    </div>
                    <div>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "700",
                          marginRight: "5px",
                        }}
                      >
                        Patient Id :{" "}
                      </label>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "400",
                        }}
                      >
                        {stopJob.patientId}
                      </label>
                    </div>
                    <div>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "700",
                          marginRight: "5px",
                        }}
                      >
                        Patient Name :{" "}
                      </label>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "400",
                        }}
                      >
                        {stopJob.patientName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: "row",
                  width: "96%",
                }}
              >
                <Button
                  onClick={() => {
                    console.log("Stop job");
                    const token = admin
                      ? localStorage.getItem(`token-${center}`)
                      : localStorage.getItem("token");
                    axios
                      .get(`${baseURLAPI}/admin/stop/${stopJob.jobId}`, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      .then((res) => {
                        console.log(res);

                        setStopJob((prevStopJob) => {
                          return {
                            ...prevStopJob,
                            status: res.data.status,
                          };
                        });

                        // setJobUpdated((prevJobUpdated) => prevJobUpdated + 1);
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                      .finally(() => {
                        setStopModalOpen(false);
                      });
                  }}
                  style={{
                    ...styles.modalButton,
                    backgroundColor: "red",
                    marginRight: "5px",
                  }}
                >
                  Stop
                </Button>
                <Button
                  style={{ ...styles.modalButton, backgroundColor: "green" }}
                  onClick={() => {
                    setStopModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            open={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
          >
            <div style={styles.deleteModal}>
              <h3>Delete Job</h3>
              <div style={styles.modalBody}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label style={{ fontWeight: "700", fontSize: "14pt" }}>
                    Are you sure you want to delete this job?
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      width: "98%",
                    }}
                  >
                    <div>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "700",
                          marginRight: "5px",
                        }}
                      >
                        Job Id :{" "}
                      </label>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "400",
                        }}
                      >
                        {deleteJob.jobId}
                      </label>
                    </div>
                    <div>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "700",
                          marginRight: "5px",
                        }}
                      >
                        Patient Id :{" "}
                      </label>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "400",
                        }}
                      >
                        {deleteJob.patientId}
                      </label>
                    </div>
                    <div>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "700",
                          marginRight: "5px",
                        }}
                      >
                        Patient Name :{" "}
                      </label>
                      <label
                        style={{
                          margin: "0",
                          fontSize: "12pt",
                          fontWeight: "400",
                        }}
                      >
                        {deleteJob.patientName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: "row",
                  width: "96%",
                }}
              >
                <Button
                  onClick={() => {
                    console.log("delete job");
                    const token = admin
                      ? localStorage.getItem(`token-${center}`)
                      : localStorage.getItem("token");
                    const formData = new FormData();
                    formData.append("job_id", deleteJob.jobId);
                    axios
                      .post(`${baseURLAPI}/admin/job_delete`, formData, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      .then((res) => {
                        console.log(res);
                        setJobUpdated((prevJobUpdated) => prevJobUpdated + 1);
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                      .finally(() => {
                        setDeleteModalOpen(false);
                      });
                  }}
                  style={{
                    ...styles.modalButton,
                    backgroundColor: "red",
                    marginRight: "5px",
                  }}
                >
                  Delete
                </Button>
                <Button
                  style={{ ...styles.modalButton, backgroundColor: "green" }}
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            open={sequencesModalOpen}
            onClose={() => {
              setSequence("");
              setSequencesModalOpen(false);
            }}
          >
            {sequence !== "" && sequence in metadata ? (
              <div style={styles.sequencesModal}>
                <div style={styles.sequencesModalRow}>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      marginLeft: "20%",
                    }}
                  >
                    Sequence:{" "}
                  </p>
                  <Select
                    style={styles.select}
                    value={sequence}
                    onChange={(e) => {
                      setSequence(e.target.value);
                      refreshImage(jobId, e.target.value);
                    }}
                  >
                    {Object.keys(metadata).map((key, index) => (
                      <MenuItem key={index} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div style={styles.sequencesModalRow}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Slice Thickness
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    {metadata[sequence].SliceThickness}
                  </p>
                </div>
                <div style={styles.sequencesModalRow}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Spacing Between Slices
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    {metadata[sequence].SpacingBetweenSlices}
                  </p>
                </div>
                <div style={styles.sequencesModalRow}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Pixel Spacing
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    {metadata[sequence].PixelSpacing}
                  </p>
                </div>
                <div style={styles.sequencesModalRow}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      color: "#1ba992",
                      cursor: "pointer",
                    }}
                  >
                    {sequence === selectedSequence ? "Used in report" : ""}
                  </p>
                  <Button
                    style={{
                      marginBottom: "10px",
                      width: "100px",
                      backgroundColor: "#1ba992",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      refreshImage(jobId, sequence);
                    }}
                  >
                    Refresh
                    <RefreshIcon style={{ marginLeft: "5px" }} />
                  </Button>
                </div>
                <img src={imgSrc} className="sequencesImg" />
                <Button
                  style={{
                    marginTop: "10px",
                    width: "300px",
                    backgroundColor: "#1b4a92",
                  }}
                  variant="contained"
                  onClick={(e) => {
                    const token = admin
                      ? localStorage.getItem(`token-${center}`)
                      : localStorage.getItem("token");
                    const formData = new FormData();
                    formData.append("job_id", jobId);
                    formData.append("sequence", sequence);
                    formData.append("run_again", "yes");
                    axios
                      .post(baseURL + "/trigger_pipeline", formData, {
                        headers: {
                          Authorization: "Bearer " + token,
                        },
                      })
                      .then((res) => {
                        console.log(res);
                        setToastMessage("Pipeline triggered");
                        setToastOpen(true);
                        setToastAnchorEl(e.currentTarget);
                        setTimeout(() => {
                          setToastOpen(false);
                        }, 2000);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  Use this sequence for pipeline
                </Button>
              </div>
            ) : (
              <div style={styles.sequencesModal}>
                <CircularProgress />
              </div>
            )}
          </Modal>
          <Modal
            open={reportModalOpen}
            onClose={() => {
              setReportModalOpen(false);
            }}
          >
            <div style={styles.reportModal}>
              <table className="table table-bordered" style={{ width: "100%" }}>
                <tr>
                  <th style={styles.reportTableHeaderItem}>Config</th>
                  <th style={styles.reportTableHeaderItem}>Actions</th>
                  <th style={styles.reportTableHeaderItem}>Sent</th>
                  <th style={styles.reportTableHeaderItem}>Config Time</th>
                </tr>
                {reportConfigs.map((config, index) => (
                  <tr key={index}>
                    <td style={styles.reportTableBodyItem}>{config.config}</td>
                    <td
                      style={styles.reportTableBodyItem}
                      className="rptModalAct"
                    >
                      <SaveIcon
                        style={{
                          fill: "#1b4b84",
                          cursor: "pointer",
                          padding: 0,
                        }}
                        onClick={() => {
                          const token = admin
                            ? localStorage.getItem(`token-${center}`)
                            : localStorage.getItem("token");
                          axios({
                            method: "GET",
                            url: `${baseURL}/report/${jobId}/${config.config}`,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                            responseType: "arraybuffer",
                          })
                            .then((res) => {
                              // download file
                              console.log("res", res);
                              let blob = new Blob([res.data], {
                                type: "application/pdf",
                              });
                              let url = window.URL.createObjectURL(blob);
                              let link = document.createElement("a");
                              link.href = url;
                              link.download = `${jobId}_${config.config}.pdf`;
                              link.click();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      />
                      <Link to={`/report/${jobId}`}>
                        <EditIcon
                          style={{
                            fill: "#1b4b84",
                            cursor: "pointer",
                            padding: 0,
                          }}
                        />
                      </Link>
                      <DeleteForever
                        style={{
                          fill: "red",
                          cursor: "pointer",
                          padding: 0,
                        }}
                        onClick={(e) => {
                          const token = admin
                            ? localStorage.getItem(`token-${center}`)
                            : localStorage.getItem("token");
                          console.log("token", token);
                          const data = {
                            job_id: jobId,
                            config_name: config.config,
                          };
                          axios
                            .post(baseURLAPI + "/config", data, {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            })
                            .then((res) => {
                              console.log(res);
                              setToastMessage("Report deleted");
                              setToastOpen(true);
                              setToastAnchorEl(e.currentTarget);
                              setTimeout(() => {
                                setToastOpen(false);
                              }, 2000);
                              getConfigs(jobId);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      />
                      {!config.sent_to_pacs ? (
                        <SendIcon
                          style={{
                            fill: "#1b4b84",
                            cursor: "pointer",
                            padding: 0,
                          }}
                          onClick={(e) => {
                            if (center == "trial") {
                              setreportconfigid(config.config);
                              setReportsendModalOpen(true);
                              setReportModalOpen(false);
                            } else {
                              const token = admin
                                ? localStorage.getItem(`token-${center}`)
                                : localStorage.getItem("token");
                              const data = {
                                job_id: jobId,
                                config_name: config.config,
                              };
                              axios
                                .post(baseURLAPI + "/send_report", data, {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                })
                                .then((res) => {
                                  console.log(res);
                                  setToastMessage("Report sent");
                                  setToastOpen(true);
                                  setToastAnchorEl(e.currentTarget);
                                  setTimeout(() => {
                                    setToastOpen(false);
                                  }, 2000);
                                  getConfigs(jobId);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }
                          }}
                        />
                      ) : (
                        <CancelScheduleSendIcon
                          style={{
                            fill: "#1b4b84",
                            cursor: "pointer",
                            padding: 0,
                          }}
                          onClick={(e) => {
                            const token = admin
                              ? localStorage.getItem(`token-${center}`)
                              : localStorage.getItem("token");
                            const data = {
                              job_id: jobId,
                              config_name: config.config,
                            };
                            axios
                              .post(baseURLAPI + "/unsend_report", data, {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              })
                              .then((res) => {
                                console.log(res);
                                setToastMessage("Report unsent");
                                setToastOpen(true);
                                setToastAnchorEl(e.currentTarget);
                                setTimeout(() => {
                                  setToastOpen(false);
                                }, 2000);
                                getConfigs(jobId);
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                        />
                      )}
                      {videoCenters.includes(center) &&
                        (!config.video_sent ? (
                          <WhatsAppIcon
                            style={{
                              fill: "#1b4b84",
                              cursor: "pointer",
                              padding: 0,
                            }}
                            onClick={(e) => {
                              const token = admin
                                ? localStorage.getItem(`token-${center}`)
                                : localStorage.getItem("token");
                              let data = {
                                job_id: jobId,
                                config_name: config.config,
                              };
                              axios
                                .post(baseURLAPI + "/send_video_report", data, {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                })
                                .then((res) => {
                                  getConfigs(jobId);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }}
                          />
                        ) : (
                          <MarkChatReadIcon />
                        ))}
                    </td>
                    <td style={styles.reportTableBodyItem}>
                      {config.sent_to_pacs ? (
                        <DoneIcon style={{ fill: "green", padding: 0 }} />
                      ) : (
                        <ClearIcon style={{ fill: "red", padding: 0 }} />
                      )}
                    </td>
                    <td style={styles.reportTableBodyItem}>
                      {config.config_time}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </Modal>
          <Modal
            open={reportsendModalOpen}
            onClose={() => {
              setReportsendModalOpen(false);
            }}
          >
            <div style={styles.reportModal}>
              <Typography variant="h5" component="div">
                Send report
              </Typography>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                label="WhatsApp Number with Country Code"
                onChange={(e) => setWhatsappNum(e.target.value)}
                value={WhatsappNum}
                style={{ marginBottom: "20px" }}
              />
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                label="email ID"
                onChange={(e) => setEmailId(e.target.value)}
                value={EmailId}
              />
              <Button
                style={{
                  marginTop: "10px",
                  width: "300px",
                  backgroundColor: "#1b4a92",
                }}
                variant="contained"
                onClick={(e) => {
                  const token = admin
                    ? localStorage.getItem(`token-${center}`)
                    : localStorage.getItem("token");
                  const data = {
                    job_id: jobId,
                    config_name: reportconfigid,
                    contact: WhatsappNum.concat("", "@c.us"),
                    contactemail: EmailId,
                  };
                  axios
                    .post(baseURLAPI + "/send_report_trial", data, {
                      headers: {
                        Authorization: "Bearer " + token,
                      },
                    })
                    .then((res) => {
                      console.log(res);
                      setresSend("report sent successfully!");
                      setTimeout(() => {
                        setresSend("");
                      }, 2000);
                    })
                    .catch((err) => {
                      setresSend("something went wrong!");
                      console.log(err);
                      setTimeout(() => {
                        setresSend("");
                      }, 2000);
                    });
                }}
              >
                Send Report
              </Button>
              {resSend && <Typography variant="body2">{resSend}</Typography>}
            </div>
          </Modal>

          <DropzoneDialogBase
            dialogTitle={DialogTitleMerge()}
            acceptedFiles={[".pdf"]}
            filesLimit={1}
            open={mergeModal}
            fileObjects={fileObjects}
            cancelButtonText={"cancel"}
            submitButtonText={"Merge"}
            Icon={NoteAdd}
            onAdd={(newFileObjs) => {
              console.log("onAdd", newFileObjs);
              console.log(newFileObjs[0].name);
              setFileObjects(newFileObjs);
            }}
            onDelete={(deleteFileObj) => {
              console.log("onDelete", deleteFileObj);
              setFileObjects([]);
            }}
            onClose={() => setMergeModal(false)}
            onSave={() => {
              const token = admin
                ? localStorage.getItem(`token-${center}`)
                : localStorage.getItem("token");
              console.log("onSave", fileObjects);
              const formData = new FormData();
              formData.append("job_id", jobId);
              formData.append("pdffile", fileObjects[0].data);
              // formData.append('update_val', 'yes');
              axios({
                method: "POST",
                url: `${baseURL}/merge_pdfs`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                data: formData,
                responseType: "arraybuffer",
              })
                .then((res) => {
                  let blob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  let url = window.URL.createObjectURL(blob);
                  let link = document.createElement("a");
                  link.href = url;
                  link.download = `${jobId}.pdf`;
                  link.click();
                  setMergeModal(false);
                })
                .catch((err) => {
                  console.log("error: ", err);
                });
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
            showPreviewsInDropzone={false}
            dropzoneText={"Drag and drop or click here"}
            onChange={(files) => console.log("Files:", files)}
          />

          <Popper
            open={toastOpen}
            anchorEl={toastAnchorEl}
            placement={toastPlacement || "bottom"}
          >
            <div style={styles.popper}>{toastMessage}</div>
          </Popper>
          <Popper
            open={errorsOpen}
            anchorEl={toastAnchorEl}
            placement={toastPlacement || "bottom"}
          >
            <div style={styles.popper}>
              {toastMessage &&
                errorsOpen &&
                toastMessage.map((error, index) => (
                  <p
                    key={index}
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    {error}
                  </p>
                ))}
            </div>
          </Popper>
          <Popper
            open={warningsOpen}
            anchorEl={toastAnchorEl}
            placement={toastPlacement || "bottom"}
          >
            <div style={styles.popper}>
              {toastMessage.other &&
                toastMessage.other.map((warning, index) => (
                  <p
                    key={index}
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    {warning}
                  </p>
                ))}
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  backgroundColor: "black",
                  margin: "5px 0px",
                }}
              ></div>
              {toastMessage.kub &&
                toastMessage.kub.map((warning, index) => (
                  <p
                    key={index}
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    {warning}
                  </p>
                ))}
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  backgroundColor: "black",
                  margin: "5px 0px",
                }}
              ></div>
              {toastMessage.calculus && toastMessage.calculus.length > 0 ? (
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "14px",
                    color: "red",
                  }}
                >
                  {toastMessage.calculus.length} stone(s) were filtered out.
                  Please check annotate viewer to see them.
                </p>
              ) : null}
            </div>
          </Popper>
        </div>
      </div>
    </div>
  );
}
